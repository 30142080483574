import { Controller } from "@hotwired/stimulus"
import axios from "axios"

export default class extends Controller {
  static targets = [ "link", "cta", "counter", "thumbnail", "title", "description", "price" ]
  static values = {
    handle: String,
    apiKey: String
  }

  connect() {
    this.fetchProduct()
    this.timer = setInterval(this.fetchProduct.bind(this), 30*1000)
  }

  disconnect() {
    clearInterval(this.timer)
  }

  async fetchProduct() {
    if (!this.hasHandleValue) return

    const url = new URL("https://reawakening-stocks.myshopify.com/api/2022-04/graphql.json")

    try {
      const { data } = await axios.post(url, {
        query: `query {
          product(handle: "${this.handleValue}") {
            title
            onlineStoreUrl
            totalInventory
            description: metafield(namespace: "global", key: "short-description") {
              value
              type
            }
            priceRange {
              maxVariantPrice {
                amount
                currencyCode
              }
            }
            featuredImage {
              url
            }
          }
        }`
      }, {
        headers: {
          "Content-Type": "application/json",
          "X-Shopify-Storefront-Access-Token": this.apiKeyValue
        }
      })

      if (this.hasLinkTarget) {
        this.linkTargets.forEach(el => el.href = data.data?.product?.onlineStoreUrl)
      }
      if (this.hasCtaTarget) {
        this.ctaTarget.innerHTML = `SHOP NOW TO SUPPORT CASA MARCELLA<br>${data.data?.product?.title}`
      }
      if (this.hasThumbnailTarget) {
        this.thumbnailTarget.src = data.data?.product?.featuredImage?.url
      }
      if (this.hasTitleTarget) {
        this.titleTarget.innerText = data.data?.product?.title
      }
      if (this.hasDescriptionTarget) {
        this.descriptionTarget.innerText = data.data?.product?.description?.value
      }
      if (this.hasPriceTarget) {
        this.priceTarget.innerText = `€ ${Number(data.data?.product?.priceRange?.maxVariantPrice?.amount).toFixed(2)}`
      }
      if (this.hasCounterTarget) {
        const decimalsCount = "100".length
        const remainingStock = data.data?.product?.totalInventory

        if (remainingStock > 0) {
          this.counterTarget.innerText = `${data.data?.product?.totalInventory?.toString()?.padStart(decimalsCount, "0")} / 100 LEFT`
        } else {
          this.counterTarget.innerText = "OUT OF STOCK"
          clearInterval(this.timer)
        }
      }
    } catch (err) {
      console.error(err)
    }
  }
}
